<template>
  <div class="online-study">
    <div class="study-head">
      <div class="app-container">
        <!--<div class="search">
          <el-autocomplete
            class="search-input"
            v-model="searchContent"
            value-key="CourseName"
            :fetch-suggestions="searchResource"
            placeholder="请输入关键字"
            :trigger-on-focus="false"
            @select="selectResource"
          ></el-autocomplete>
          &lt;!&ndash;<el-input class="search-input" placeholder="请输入关键字"></el-input>&ndash;&gt;
          <el-button class="search-btn" type="primary" @click="searchResourceList(searchContent)">搜索</el-button>
        </div>-->
        <div class="catalogue">
          <div class="title-box">
            <div class="title">课程目录：</div>
            <div class="search">
              <el-autocomplete
                class="search-input"
                v-model="searchContent"
                value-key="CourseName"
                :fetch-suggestions="searchResource"
                placeholder="请输入关键字"
                :trigger-on-focus="false"
                @select="selectResource"
              ></el-autocomplete>
              <!--<el-input class="search-input" placeholder="请输入关键字"></el-input>-->
              <div>
                <el-button class="search-btn" type="primary" @click="searchResourceList(searchContent)">搜索</el-button>
              </div>
            </div>
          </div>
          <div class="main">
            <div class="content">
              <div class="catalogue-title">高中：</div>
              <div class="catalogue-content">
                <el-radio-group v-model="courseCatalogue" @change="getCatalogueList">
                  <el-radio-button
                    v-for="(item,index) in subjectList"
                    :key="index"
                    :label="item.ID"
                  >{{item.Subject}}
                  </el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-container">
      <div class="study-main">
        <div class="left">
          <div class="left-title">
            视频资源目录
          </div>
          <div class="full-path" v-show="fullPath" :title="fullPath">{{fullPath}}
            <div class="close-mask" @click="initCatalog">取消选择</div>
          </div>
          <div class="left-content">
            <el-tree
              :data="catalogList"
              :props="showProps"
              node-key="value"
              :highlight-current="true"
              empty-text="暂无数据..."
              :default-expand-all="true"
              :expand-on-click-node="false"
              @node-click="getResourceListFromCatalogueList"
            ></el-tree>
          </div>
        </div>
        <div class="right">
          <div class="right-sort">
            <div class="grade">
              <div class="grade-title">年级：</div>
              <ul class="grade-box">
                <li class="grade-item" :class="{'selected': isActive==='all'}" @click="selectGrade()">全部</li>
                <li class="grade-item" :class="{'selected': isActive===index}" v-for="(item, index) in gradeList"
                    :key="index" @click="selectGrade(true, item, index)">{{item.GradeName}}
                </li>
              </ul>
            </div>
          </div>
          <div style="height: 2rem; background-color: #f0f0f0;"></div>
          <div class="right-list">
            <ul class="list-box" v-if="resourceList.length !== 0">
              <li class="list-item" v-for="(item,index) in resourceList" :key="index">
                <div class="item" @click="toResourceDetails(item)">
                  <!--<div class="item-name">{{item.CourseName}}</div>-->
                  <div class="item-img">
                    <img class="cover-img" :src="item.CoverImage?item.CoverImage:defaultCoverImage" alt="">
                    <div class="item-mask">
                      <img class="play" src="@/assets/images/play.png" alt="">
                    </div>
                  </div>
                  <div class="item-describe" :title="item.CourseName">{{item.CourseName}}</div>
                </div>
              </li>
            </ul>
            <div class="no-more" v-else>暂无数据...</div>
          </div>
          <div>
            <div class="pagination">
              <el-pagination
                v-show="resourceMainData.Total>0"
                background
                layout="prev, pager, next"
                :page-size="resourceListParams.PageSize"
                :total="resourceMainData.Total"
                :current-page.sync="resourceListParams.PageIndex"
                @current-change="turnPage"
              >
              </el-pagination>
            </div>
            <!-- <vip-limit-cover v-else :onlineStudy="true"></vip-limit-cover> -->
          </div>
        </div>
      </div>
      <!--<el-button @click="$router.push('/study/study-details')">详情</el-button>-->
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
  // import {
  //   SubjectList,
  //   CatelogList,
  //   ResourceList,
  //   ResourceListBySearch,
  //   GradeList,
  // } from "@/api/onlineStudy"
  // import VipLimitCover from '@/views/wish/VIPLimitCover'
  // import {getToken} from '@/utils/auth'
  // import { getStore } from '@/utils/utils'

  export default {
    name: "online-study",
    components: {
      // VipLimitCover,
    },
    data() {
      return {
        searchContent: '',
        courseCatalogue: '',
        subjectList: [],
        catalogList: [],
        resourceList: [],
        resourceMainData: {},
        showProps: {
          children: 'nodes',
          label: 'text'
        },

        // GKNecessaryPower: this.$store.state.user.hasGKNecessary,
        // hasToken: getStore('LoginData'),
        // rolePower: this.$store.getters.roles,

        //年级参数
        gradeList: [],
        isActive: 'all',

        fullPath: '',

        //资源列表参数
        resourceListParams: {
          SubjectId: null,
          CatelogId: null,
          GradeId: null,
          PageIndex: 1,
          PageSize: 12,
        },
        defaultCoverImage: require('@/assets/images/onlineStudy-videoCover.jpg'),
      }
    },
    watch: {
      $route(to, from) {
        if (this.$route.query.ID) {
          this.courseCatalogue = this.$route.query.ID
          this.getCatalogueList(this.courseCatalogue)
        }
      }
    },
    created() {

    },
    mounted() {
      this.init()

    },
    activated() {
    },
    deactivated() {
    },
    methods: {
      //取消选中目录项
      initCatalog() {
        this.fullPath = ''
        this.resourceListParams.PageIndex = 1
        this.getResourceList()
      },
      //年级筛选
      selectGrade(select, item, index) {
        this.load()
        // 清空目录
         this.resourceListParams.CatelogId = null;
         this.fullPath = '';
         this.resourceList = [];
        if (select) {
          this.resourceListParams.GradeId = item.ID
          this.isActive = index
          this.getListData()
        } else {
          //all
          this.resourceListParams.GradeId = null
          this.isActive = 'all'
          this.getListData()
        }
      },
      //从资源目录获取资源列表
      getResourceListFromCatalogueList(nodeObject) {
        this.resourceListParams.GradeId = null;
         this.isActive = 'all'
        this.fullPath = nodeObject.fullPath
        this.resourceListParams.PageIndex = 1
        this.getResourceList(nodeObject)
      },
      //获取资源列表
      getResourceList(nodeObject) {
        //不能直接写入Page Index = 1,翻页也调用的该方法
        this.load()
        if (nodeObject) {
          this.resourceListParams.CatelogId = nodeObject.value
          this.getListData()
        } else {
          this.resourceListParams.CatelogId = null,
            this.resourceList = []
          this.getListData()
          this.load(true)
        }
      },
      //列表数据调取
      getListData() {
        API.Course.ResourceList(this.resourceListParams).then(res => {
          this.resourceList = res.data.Data
          this.resourceMainData = res.data
          this.load(true)
        }).catch(err => {
          this.load(true)
        })
      },

      //获取资源目录
      getCatalogueList(item) {
        this.load()
        this.$router.push({
          path: '/study',
          query: {
            ID: item
          }
        })
        this.fullPath = ''
        API.Course.CatelogList({
          SubjectId: item
        }).then(res => {
          this.catalogList = res.data
          this.resourceListParams.PageIndex = 1
          this.resourceListParams.SubjectId = item
          // this.getResourceList(this.catalogList[0])
          this.getResourceList()
        }).catch(err => {
          this.load(true)
        })
      },

      //进入资源播放详情列表
      toResourceDetails(params) {
        if (params.CatelogId) {
          this.$router.push({
            path: '/study-detail',
            query: {
              Id: params.ID,
              SubjectId: this.resourceListParams.SubjectId,
              CatelogId: this.resourceListParams.CatelogId,
              GradeId: this.resourceListParams.GradeId,
            }
          })
        } else {
          this.$router.push({
            path: '/study-detail',
            query: {
              Id: params.ID,
              CatelogId: this.resourceListParams.CatelogId,
              GradeId: this.resourceListParams.GradeId,
              SubjectId: this.resourceListParams.SubjectId,
            }
          })
        }
      },

      //搜索资源回调
      searchResource(keyword, cb) {
        API.Course.ResourceListBySearch({
          SearchKey: keyword,
          PageIndex: 1,
          PageSize: 999999
        }).then(res => {
          cb(res.data.Data)
        })
      },

      //选择搜索结果
      selectResource(item) {
        this.$router.push({
          path: '/study-detail',
          query: {
            Id: item.ID,
            CatelogId: item.CatelogId
          }
        })
      },
      //点击搜索
      searchResourceList(keyword) {
        this.load()
        API.Course.ResourceListBySearch({
          SearchKey: keyword,
          PageIndex: 1,
          PageSize: 999999
        }).then(res => {
          this.resourceList = res.data.Data
          this.load(true)
        }).catch(err => {
          this.load(true)
        })
      },

      //翻页
      turnPage(page) {
        this.resourceListParams.PageIndex = page
        this.getResourceList({value: this.resourceListParams.CatelogId})
      },

      init() {
        
        //获取科目列表
        this.load()
        API.Course.SubjectList({
          SchoolLevel: 3
        }).then(res => {
          this.subjectList = res.data
          if (this.$route.query.ID) {
            this.courseCatalogue = this.$route.query.ID
            this.getCatalogueList(this.$route.query.ID)
          } else {
            this.courseCatalogue = this.subjectList[0].ID
            this.getCatalogueList(this.subjectList[0].ID)
          }
        }).catch(err => {
          this.load(true)
        })

        //grade
        API.Course.GradeList({
          SchoolLevel: 3
        }).then(res => {
          this.gradeList = res.data
        }).catch(err => {

        })

      },

      load(e) {
        let loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0,0,0,0.5)'
        })

        if(e) {
          loading.close()
        }
      }
    }
  }
</script>

<style scoped lang="less">

  .online-study {
    /*height: 50vh;*/
    width: 100%;
    background-color: #f0f0f0;

    .study-head {
      width: 100%;
      background-color: #fff;
      .catalogue {
        .title-box {
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;
          .title {
            padding: 1rem 0;
            font-weight: 700;
          }

          .search {
            width: 30rem;
            display: flex;
            justify-content: center;
            .search-input {
              width: 100%;
              margin-right: 8px;
            }
          }
        }
        .main {
          padding-bottom: 1rem;

          .content {
            display: flex;
            align-items: center;
            .catalogue-title {
              margin-right: 1rem;
            }
          }
        }

        ::v-deep .el-radio-button {
          margin: 1rem;
        }

        ::v-deep .el-radio-button__inner {
          border: 1px solid #e0e0e0;
          border-radius: 4px;
        }
        ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
          box-shadow: none;
        }
      }
    }
    .study-main {
      display: flex;
      margin-top: 2rem;

      .left {
        width: 20%;
        margin-right: 2rem;
        margin-bottom: 2rem;
        border: 1px solid #e0e0e0;
        background: #fff;

        .left-title {
          padding: 1rem;
          text-align: center;
          background-color: rgba(var(--themecolor),0.75);
          color: #fff;
        }
        .full-path {
          padding: .5rem 1rem;
          font-size: 14px;
          color: rgba(var(--themecolor),1);
          cursor: pointer;
          background-color: #f0f0f0;
          position: relative;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:hover {
            .close-mask {
              /*display: block;*/
              opacity: 1;
            }
          }

          .close-mask {
            /*display: none;*/
            opacity: 0;
            text-align: center;
            line-height: 2;
            color: #fff;
            font-size: 15px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0,0,0,.6);
            transition: opacity .3s linear;
          }
        }
        .left-content {
          max-height: 46.5rem;
          padding-top: 1rem;
          overflow: auto;
          ::v-deep .el-tree {
            overflow: auto;
          }

          ::v-deep .el-tree-node__label {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
              color: rgba(var(--themecolor),1);
            }
          }
        }
      }
      .right {
        width: calc(80% - 2rem);
        background-color: #fafafa;
        margin-bottom: 2rem;
        position: relative;

        .right-sort {
          padding: 2rem;

          .grade {
            display: flex;
            align-items: center;
            .grade-title {
              font-size: 18px;
              color: #3E4145;
              margin-right: 2rem;
            }
            .grade-box {
              display: flex;
              .grade-item {
                font-size: 16px;
                border: 1px solid #e0e0e0;
                border-radius: 3px;
                padding: .5rem 1.2rem;
                margin-right: 1rem;
                cursor: pointer;
                transition: color .3s linear;
                &:hover {
                  color: rgba(var(--themecolor),1);
                }
              }

              .selected {
                background-color: rgba(var(--themecolor),0.75);
                color: #fff;
                border: 1px solid rgba(var(--themecolor),0.75);

                &:hover {
                  color: #fff;
                }
              }
            }
          }
        }
        .right-list {
          margin-bottom: 4rem;

          .list-box {
            display: flex;
            flex-wrap: wrap;

          }
          .list-item {
            width: 25%;
            text-align: center;
            margin: 1rem 0;

            .item {
              width: 12rem;
              margin: 0 auto;
              text-align: center;

              .item-name {
                padding: .2rem 0;
                color: #3E4145;
                font-size: 16px;
              }

              .item-img {
                width: 100%;
                height: 8rem;
                position: relative;
                cursor: pointer;
                border-radius: 5px;
                box-shadow: 0 4px 8px 0 rgba(87, 113, 173, 0.2);
                .cover-img {
                  width: 100%;
                  height: 100%;
                  vertical-align: top;
                  border-radius: 5px;

                }
                .item-mask {
                  opacity: 0;
                  transition: all .15s linear;
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  background-color: rgba(0, 0, 0, 0.29);
                  z-index: 8;
                  .play {
                    width: 3rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
                &:hover .item-mask {
                  opacity: 1;
                  border-radius: 5px;
                }

              }
              .item-describe {
                padding: .5rem .5rem 0;
                font-size: 14px;
                cursor: pointer;
                /*white-space: nowrap;*/
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
              &:hover .item-describe {
                color: rgba(var(--themecolor),1);
              }

            }
          }
        }
        .no-more {
          padding: 10rem 5rem;
          color: rgb(54, 134, 226);
          font-size: 18px;
          text-align: center;
        }

        .pagination {
          text-align: center;
          position: absolute;
          bottom: 1rem;
          left: 50%;
          transform: translateX(-50%);
        }

      }

    }
  }

</style>
<style lang="less">
.online-study .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.online-study .el-radio-button__orig-radio:checked + .el-radio-button__inner{
  background-color: rgba(var(--themecolor),0.75);
}
.online-study .el-radio-button__inner:hover{
  color: rgba(var(--themecolor),1);
}
.online-study .el-button--primary,.online-study .el-button--primary:focus{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.online-study .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.online-study .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.online-study .el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.online-study .el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.online-study .el-pagination.is-background .btn-next:hover, .online-study .el-pagination.is-background .btn-prev:hover, .online-study .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>